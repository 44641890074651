import React, { useEffect } from 'react';

import { useMutation } from 'react-apollo';
import { navigate } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Error from '../../components/Common/Error';
import Loading from '../../components/Common/Loading';
import { main } from '../../themes/main';

import { ASSIGN_MEDICAL_CASE_COLLABORATION } from '../../graphql/mutations';

export default ({ medicalCaseCollaborationId }) => {
  const [assignMedicalCaseCollaboration, { error }] = useMutation(
    ASSIGN_MEDICAL_CASE_COLLABORATION,
    {
      onCompleted: () => { navigate('/dashboard'); },
    },
  );

  useEffect(() => {
    assignMedicalCaseCollaboration({ variables: { uuid: medicalCaseCollaborationId } });
  }, []);

  return (
    <ThemeProvider theme={main}>
      {error ? (
        <Error error={error.message} />
      ) : (
        <Loading />
      )}
    </ThemeProvider>
  );
};
