import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

export default ({ error }) => {
  const { t } = useTranslation();

  return (
    <ErrorWrapper>
      <strong>{`${t('error')}: `}</strong>
      { error }
    </ErrorWrapper>
  );
};

export const ErrorWrapper = styled.div`
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  display: inline-block;
  color: ${(props) => props.theme.colors.danger};
  position: relative;
  padding: 7px 10px;
  margin: 10px 0px;
`;
